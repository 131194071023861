import request from '@/utils/request'
import constant from '@/constant'

function pageList(formData){
  return request.post(constant.serverUrl + "/personDeviceLog/pageList", formData);
}

function exportXls(formData) {
  //导出xls
  return request.post(constant.serverUrl + "/personDeviceLog/exportXls", formData);
}

function exportProgress(timestamp) {
  //导出xls
  return request.get(constant.serverUrl + `/personDeviceLog/exportProgress?timestamp=${timestamp}`);
}

function update(formModel){  
  return request.post(constant.serverUrl + "/personDeviceLog/update", formModel,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

export default {
  pageList,exportXls,exportProgress,update
}